import { useState } from 'react';

function HeadcountEditing(props: {
  defaultValue: number;
  max: number;
  onSave: (value: number) => void;
}) {
  const { defaultValue, max, onSave } = props;

  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState<React.ReactNode | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number(e.target.valueAsNumber));
    setError(null);
  };

  const handleSave = () => {
    if (value > max) {
      setError(
        <>
          For a group of {max + 1} or more, please{' '}
          <a
            href='mailto:support@lunapark.com'
            className='text-primary underline'
          >
            contact us
          </a>
          .
        </>
      );
      return;
    }
    onSave(value);
  };

  return (
    <div>
      <div className='flex items-center gap-3'>
        <input
          className='field rounded w-20 h-6 mb-0 text-center'
          type='number'
          value={value || ''}
          onChange={handleChange}
        />
        <button
          type='button'
          onClick={handleSave}
          className='btn text-sms text-primary'
        >
          Save
        </button>
      </div>
      {error && <div className='mt-1 text-3xs'>{error}</div>}
    </div>
  );
}

export function HeadcountEditor(props: {
  headcount: number;
  options: number[];
  onHeadcountChange: (headcount: number) => void;
}) {
  const { headcount, options, onHeadcountChange } = props;

  const [editingHeadcount, setEditingHeadcount] = useState<boolean>(false);

  const sorted = options.sort((a, b) => a - b);
  const lower = (sorted.find((option) => option < headcount) || 0) + 1;
  const higher =
    sorted.find((option) => option >= headcount) || sorted[sorted.length - 1];

  if (editingHeadcount) {
    return (
      <HeadcountEditing
        defaultValue={headcount}
        max={options[options.length - 1]}
        onSave={(headcount) => {
          onHeadcountChange(headcount);
          setEditingHeadcount(false);
        }}
      />
    );
  }

  return (
    <div className='flex items-center gap-3'>
      <div className='h-6 bg-lp-gray-003 rounded px-2 text-sms flex justify-center items-center'>
        {lower < higher ? `${lower}–${higher} people` : `${headcount} people`}
      </div>
      <button
        type='button'
        onClick={() => setEditingHeadcount(true)}
        className='btn text-sms text-primary'
      >
        Edit
      </button>
    </div>
  );
}
